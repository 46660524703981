import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { useState, useCallback, Dispatch, ChangeEvent } from 'react';
import { TextField, Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import ScanSurvey from '../model/scan-survey';
import HeaderAppBar from 'component/header-app-bar';
import FooterAppBar from 'component/footer-app-bar';

interface FormValueSetters {
	onClinicChange: (clinic: string) => void
}

interface ContainerProps {
	onSubmit?: (form: ScanSurvey) => void
}
type ViewProps = ScanSurvey & FormValueSetters & {
	showError: boolean,
	onSubmit: () => void
};

const SurveyContainer: React.FunctionComponent<ContainerProps> = (props: ContainerProps) => {
	const [firstAttempt, setFirstAttempt] = useState(true); 
	const [clinic, setClinic] = useState("");

	const onClinicChange = useCallback(
		(clinic: string) => setClinic(clinic),
		[setClinic]
	);

	const onSubmit = useCallback(
		() => {
			setFirstAttempt(false);

			const tClinic = clinic.trim();
			if (validateClinic(tClinic)) {
				props.onSubmit?.({
					clinic: tClinic
				});
			}
		},
		[props.onSubmit, clinic, setFirstAttempt]
	);

	return (
		<SurveyView 
		{ ...{ 
			clinic, 
			onClinicChange,
			onSubmit,
			showError: !firstAttempt
		} }
		/>
	);
};
export default SurveyContainer;

const useStyles = makeStyles(theme =>
createStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '100%'
	},
	form: {
		'& .MuiTextField-root': {
			width: '100%',
		},
	},
	container:{
		backgroundColor: theme.background.main,
		flex: 'auto'
	},
	content: {
		padding: '30px'
	},
	backButton: {
		marginRight: theme.spacing(2),
	}
}),
);

const SurveyView: React.FunctionComponent<ViewProps> = (props: ViewProps) => {
	const classes = useStyles();
	const history = useHistory();

	const handleClinicChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => props.onClinicChange(e.target.value), 
		[props.onClinicChange]
	);

	const onClickBack = useCallback(() => history.goBack(), [history]);

	let buttonText = '提交';

	const validClinic = validateClinic(props.clinic);

	return (
		<Box className={classes.root}>
			<HeaderAppBar>
				<IconButton edge="start" className={classes.backButton} onClick={onClickBack} color="inherit" aria-label="menu">
					<ArrowBackIosIcon />
				</IconButton>
				<Typography variant="h6">
					問卷調查
				</Typography>
			</HeaderAppBar>

			<Grid container justify="center" className={classes.container}>
				<Grid item xs={12} sm={6} md={3} className={classes.content}>
					<Box>
						<Typography variant="body1">
							為了讓我們更加了解您的需求和讓我們能夠提供更好服務，請您提供接受PROFHILO療程的診所或醫學美容中心名稱。
						</Typography>
					</Box>
					<br/>
					<form className={classes.form} noValidate autoComplete="off">
						<TextField
						id="clinic"
						label="診所/醫學美容中心"
						variant="filled"
						error={props.showError ? !validClinic : false}
						value={props.clinic}
						helperText={(props.showError && !validClinic) ? '名稱無效' : null}
						onChange={handleClinicChange}
						/>
					</form>
					<br/>
					<Box textAlign="center">
						<Button 
						variant="contained" 
						color="secondary" 
						disabled={!validClinic}
						onClick={props.onSubmit}>
							{ buttonText }
						</Button>
					</Box>
				</Grid>
			</Grid>

			<FooterAppBar showLeftLogo/>
		</Box>
	);
};

function validateClinic(clinic: string): boolean {
	const matched = clinic.trim().match(/^.{2,100}$/);
	return !!matched;
}

