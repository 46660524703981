import * as React from 'react';
import * as ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import AppRouter from './view/app'
import { useEndpoint, snsClient, SNSClientContext } from 'service/sns';
import theme from 'styles/theme';

useEndpoint(SNS_API_ENDPOINT);
const sns = snsClient();

const App = () => {
	return (
		<>
			<CssBaseline/>
			<ThemeProvider theme={theme}>
				<SNSClientContext.Provider value={sns}>
					<AppRouter/>
				</SNSClientContext.Provider>
			</ThemeProvider>
		</>
	);
};

export function renderApp(root: HTMLElement) {
	ReactDOM.render(<App/>, root);
};