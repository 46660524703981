import * as React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import HomeView from './home';
import ScanPipelineRoute from './scan-pipeline';

const AppRouter: React.FunctionComponent = () => {
	return (
		<Router>
			<Switch>
				<Route path="/scan">
					<ScanPipelineRoute/>
				</Route>
				<Route path="/">
					<HomeView/>
				</Route>
			</Switch>
		</Router>
	);
}
export default AppRouter;
