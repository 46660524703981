import { createContext } from 'react';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import GS1Code from 'model/gs1code';
import ScanSurvey from 'model/scan-survey';
import { ProductInstanceSumamry } from "model/product";
import * as error from "error";

let endpoint: string = "";
export function useEndpoint(url: string) {
	endpoint = url;
}

export function snsClient() {
	return new SNSClient(endpoint);
}

export class SNSClient {
	readonly endpoint: string;
	readonly ax: AxiosInstance;

	constructor(url: string) {
		this.endpoint = url;
		this.ax = axios.create({
			baseURL: this.endpoint
		});
	}

	async scanCode(code: GS1Code, survey: ScanSurvey): Promise<ProductInstanceSumamry> {
		const resp: AxiosResponse = await this.ax.post('/scan', {
			gtin: code.gtin,
			serial_number: code.serial,
			lot: code.lot,
			expiry_date: code.expiry,
			survey
		});

		const data = resp.data;

		if (data.success) {
			const result = data.result;
			const prod = {
				name: result.info.name,
				desc: result.info.desc,
			};

			const instance = {
				gtin: result.instance.gtin,
				serial: result.instance.serial_number,
				lot: result.instance.lot,
				expiry: result.instance.expiry_date,
			}
	
			return {
				info: prod,
				instance,
				count: result.count,
				last_scan_at: new Date(result.last_scan_at * 1000)
			}
		} else {
			const err = data.error;
			switch (err.code) {
				case "item-not-found":
					throw new error.ProductInstanceNotFoundError();
				default:
					throw new error.OperationError(err.code, err.message);
			}
		}
	}
}

export const SNSClientContext = createContext(snsClient());