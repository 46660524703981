import memoizeOne from 'memoize-one';
import { BrowserDatamatrixCodeReader, HTMLVisualMediaElement } from "@zxing/browser";
import { int } from '@zxing/library/esm/customTypings';

const blackWhiteThreshold: int = 380; 	// 255 * 3 / 2
const adjustFactor: int = 2;

export default class CroppedCodeReader extends BrowserDatamatrixCodeReader {
	readonly factor: number;
	private readonly memDimension: typeof srcDimension;
	private readonly memCrop: typeof calculateCropped;

	constructor(factor: number) {
		super();
		this.factor = factor;
		this.memDimension = memoizeOne(srcDimension);
		this.memCrop = memoizeOne(calculateCropped);
	}

	public drawImageOnCanvas(canvasElementContext: CanvasRenderingContext2D, srcElement: HTMLVisualMediaElement): void {
		let [srcWidth, srcHeight] = this.memDimension(srcElement);
		let [cx, cy, cWidth, cHeight] = this.memCrop(this.factor, srcWidth, srcHeight);
		canvasElementContext.drawImage(srcElement, cx, cy, cWidth, cHeight, 0, 0, srcWidth, srcHeight);
		let imgData = canvasElementContext.getImageData(0, 0, srcWidth, srcHeight);
		adjustBWContrast(imgData);
		canvasElementContext.putImageData(imgData, 0, 0);
	}
} 

function srcDimension(srcElement: HTMLVisualMediaElement): [number, number] {
	if (srcElement instanceof HTMLVideoElement) {
		return [srcElement.videoWidth, srcElement.videoHeight];
	}

	const img = srcElement as HTMLImageElement;
	return [img.naturalWidth || img.width, img.naturalHeight || img.height];
}

function calculateCropped(factor: number, width: number, height: number): [number, number, number, number] {
	const cropWidth = width * factor;
	const cropHeight = height * factor;
	const cropX = (width - cropWidth) / 2;
	const cropY = (height - cropHeight) / 2;

	return [Math.ceil(cropX), Math.ceil(cropY), Math.floor(cropWidth), Math.floor(cropHeight)];
}

// Adjust in place the black and white constract of the image data
function adjustBWContrast(img: ImageData) {
	const data = img.data;
	for (let i = 0; i < data.length; i += 4) {
		let r = data[i];
		let g = data[i + 1];
		let b = data[i + 2];

		const color = r + g + b;

		if (color < blackWhiteThreshold) {
			// black-ish
			// decrease the color by a certain factor
			r = Math.floor(r / adjustFactor);
			g = Math.floor(g / adjustFactor);
			b = Math.floor(b / adjustFactor);
		} else {
			// white-ish 
			// increase the color by a certain factor
			r = Math.ceil(r * adjustFactor);
			g = Math.ceil(g * adjustFactor);
			b = Math.ceil(b * adjustFactor);
		}

		// reassign the new color to the image
		img.data[i] = r > 255 ? 255 : r;
		img.data[i + 1] = g > 255 ? 255 : g;
		img.data[i + 2] = b > 255 ? 255 : b;
	}
}