import * as React from 'react';
import { useRef, useState, useCallback, useContext, useEffect } from 'react';
import { Switch, Route, RouteProps, useRouteMatch, useHistory, Redirect } from 'react-router-dom';

import ScannerContainer from './scanner';
import SurveyContainer from './survey';
import ResultContainer from './result';
import CodeEntryContainer from './code-entry';
import GS1Code from 'model/gs1code';
import ScanSurvey from 'model/scan-survey';
import { SNSClientContext } from 'service/sns';
import { ProductInstanceSumamry } from 'model/product';

enum PipelineStep {
	Scan = 1 << 0,
	Survey = 1 << 1,
	Result = 1 << 2,
}

interface ViewProps {
	allowedSteps: PipelineStep,
	code?: GS1Code,
	pending: boolean, 
	result?: ProductInstanceSumamry,
	onDetected: (code: GS1Code) => void,
	onCodeEntryClose: () => void,
	onCodeEntrySubmit: (code: GS1Code) => void,
	gotoCodeEntry: () => void, 
	onSubmit: (form: ScanSurvey) => void
}

const ScanPipelineContainer: React.FunctionComponent = () => {
	const history = useHistory();
	const match = useRouteMatch();
	const snsClient = useContext(SNSClientContext);

	const [subInProgress, setSubInProgress] = useState(false);
	const [steps, setSteps] = useState(PipelineStep.Scan);
	const [gs1Code, setGS1Code] = useState<GS1Code>();
	const [form, setForm] = useState<ScanSurvey>();
	const [pending, setPending] = useState<boolean>(false);
	const [result, setResult] = useState<ProductInstanceSumamry>();

	const prevGS1Code = useRef<GS1Code>();
	const prevForm = useRef<ScanSurvey>();

	// pipeline flow
	useEffect(() => {
		if (form != prevForm.current) {
			(async () => {
				if (gs1Code && form) {
					setPending(true);
					setResult(undefined);

					const prom = snsClient.scanCode(gs1Code, form);

					setSteps(steps | PipelineStep.Result);
					history.push(`${match.path}/result`)
					
					try {
						const summary = await prom;
						setResult(summary);
					} catch (err) {
						console.log(err);
						setResult(undefined);
					} finally {
						setPending(false);
					}
				}
			})();
		} else if (gs1Code != prevGS1Code.current) {
			setSteps(steps | PipelineStep.Survey);
			history.push(`${match.path}/survey`);
		}

		// update previous refs
		prevGS1Code.current = gs1Code;
		prevForm.current = form;
	}, [gs1Code, form])

	const onDetected = useCallback((code: GS1Code) => {
		if (!subInProgress) {
			setGS1Code(code);
		}
	}, []);

	const onSubmit = useCallback((form: ScanSurvey) => {
		if (!subInProgress) {
			setForm(form);
		}
	}, []);

	const gotoCodeEntry = useCallback(() => {
		history.push(`${match.path}/entry`);
	}, [])

	const onCodeEntryClose = useCallback(() => {
		history.push(`${match.path}`);
	}, []);

	const onCodeEntrySubmit = useCallback((code: GS1Code) => {
		if (!subInProgress) {
			setGS1Code(code);
		}
	}, []);

	return <ScanPipelineView 
	allowedSteps={ steps }
	code={gs1Code}
	pending={pending}
	result={result}
	{ ...{
		onDetected, 
		onSubmit, 
		onCodeEntryClose,
		onCodeEntrySubmit,
		gotoCodeEntry
	} }/>;
}

const ScanPipelineView: React.FunctionComponent<ViewProps> = (props: ViewProps) => {
	const match = useRouteMatch();
	
	return (
		<Switch>
			<SurveyRoute 
			path={`${match.path}/survey`}
			onSubmit={props.onSubmit}
			isAllow={isStepAllowed(PipelineStep.Survey, props.allowedSteps)}/>
			<ResultRoute 
			path={`${match.path}/result`}
			isAllow={isStepAllowed(PipelineStep.Result, props.allowedSteps)}
			code={props.code}
			pending={props.pending}
			result={props.result}/>
			<Route path={`${match.path}/entry`}>
				<CodeEntryContainer onSubmit={props.onCodeEntrySubmit} onClose={props.onCodeEntryClose}/>
			</Route>
			<Route path={match.path}>
				<ScannerContainer onDetected={props.onDetected} gotoCodeEntry={props.gotoCodeEntry}/>
			</Route>
		</Switch>
	)
}
ScanPipelineView.defaultProps = {
	pending: false
};

export default ScanPipelineContainer;

type SuveryRouteProps = RouteProps & {
	onSubmit: (form: ScanSurvey) => void,
	isAllow: boolean,
}
const SurveyRoute: React.FunctionComponent<SuveryRouteProps> = ({ isAllow, onSubmit, ...rest }) => {
	if (isAllow) {
		return (
			<Route {...rest}>
				<SurveyContainer onSubmit={onSubmit} />
			</Route>
		);
	} else {
		return <Redirect to=""></Redirect>
	}
}

type ResultRouteProps = RouteProps & {
	isAllow: boolean,
	pending: boolean,
	code?: GS1Code,
	result?: ProductInstanceSumamry
}
const ResultRoute: React.FunctionComponent<ResultRouteProps> = ({ isAllow, pending, code, result, ...rest }) => {
	if (isAllow && code) {
		return (
			<Route {...rest}>
				<ResultContainer { ...{pending, code, result} }/>
			</Route>
		)
	} else {
		return <Redirect to=""></Redirect>
	}
}

// Helper Methods
//

function isStepAllowed(step: PipelineStep, allowedSteps: PipelineStep): boolean {
	return (allowedSteps & step) == step;
}