import { GS1Reader } from 'gs1js';

const gs1regex = /^01(\d{13})10([\da-zA-Z]{7})17(\d{6})21([\da-zA-Z]*)$/;

export default class GS1Code {
	readonly gtin: string
	readonly serial: string
	readonly lot: string
	readonly expiry: string

	constructor(gtin: string, serial: string, lot: string, expiry: string) {
		this.gtin = gtin.replace(/\s+/g, '');
		this.serial = serial.replace(/\s+/g, '');
		this.lot = lot.replace(/\s+/g, '');
		this.expiry = expiry.replace(/\s+/g, '');
	}

	// Parse a GS1 code and extract the GTIN, serial, lot number and expiry date.
	// Because the datamatrix on some of the packaging were not encoded correctly, 
	// the input code is first parsed using a regexp to detect these improper GS1 code. 
	static parse(code: string): GS1Code | null {
		const match = code.match(gs1regex);
		if (match) {
			// This is not a proper GS1 parser as the product's datamatrix does NOT
			// conform to the GS1 specification
			// 1. the encoded GTIN is only 13 digit long (with the leading '0' removed)
			// 2. the <FNC1> and <GS> separatores are missing
			return new GS1Code(`0${match[1]}`, match[4], match[2], match[3])
		} else {
			try {
				// Parse the proper GS1 code
				const reader = new GS1Reader(code);

				const gtin = reader.getApplicationIdentifierById('01');
				const serial = reader.getApplicationIdentifierById('21');
				const lot = reader.getApplicationIdentifierById('10');
				const expiry = reader.getApplicationIdentifierById('17');
	
				return new GS1Code(gtin.value, serial.value, lot.value, expiry.value);
			} catch {
				// Code is not valid
				return null;
			}
		}
	}
}
