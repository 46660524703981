import * as React from 'react';

import { useState, useCallback, Dispatch, ChangeEvent } from 'react';
import { TextField, Button, FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import HeaderAppBar from 'component/header-app-bar';
import FooterAppBar from 'component/footer-app-bar';
import GS1Code from 'model/gs1code';

const FIXED_CODE_ENTRY_GTINS = ['08033638950951', '08033638957578', '08033638956052'];

interface ContainerProps {
	onClose: () => void,
	onSubmit?: (code: GS1Code) => void
}

interface FormValueSetters {
	onGtinChange: (gtin: string) => void,
	onLotChange: (lot: string) => void,
	onSerialChange: (serial: string) => void,
	onExpiryChange: (expiry: string) => void
}

type ViewProps = FormValueSetters & {
	gtin: string,
	lot: string, 
	serial: string,
	expiry: string,
	showError: boolean,
	onSubmit: () => void, 
	onClose: () => void
};

const CodeEntryContainer: React.FunctionComponent<ContainerProps> = (props: ContainerProps) => {
	const [firstAttempt, setFirstAttempt] = useState(true); 
	const [gtin, setGtin] = useState(FIXED_CODE_ENTRY_GTINS[0])
	const [lot, setLot] = useState("");
	const [serial, setSerial] = useState("");
	const [expiry, setExpiry] = useState("");
 
	const onGtinChange = useCallback(
		(gtin: string) => setGtin(gtin),
		[setGtin]
	);

	const onLotChange = useCallback(
		(lot: string) => setLot(lot),
		[setLot]
	);

	const onSerialChange = useCallback(
		(serial: string) => setSerial(serial),
		[setSerial]
	);

	const onExpiryChange = useCallback(
		(expiry: string) => setExpiry(expiry),
		[setExpiry]
	);

	const onSubmit = useCallback(
		() => {
			setFirstAttempt(false);

			if (validateGtin(gtin) && validateLot(lot) && validateSerial(serial) && validateExpiry(expiry)) {
				const gs1 = new GS1Code(
					gtin, 
					serial.toLocaleUpperCase(), 
					lot, 
					convertExpiry(expiry)
					);
				props.onSubmit?.(gs1);
			}
		},
		[props.onSubmit, gtin, lot, serial, expiry, setFirstAttempt]
	);

	return (
		<CodeEntryView { ...{
			gtin,
			lot,
			serial,
			expiry,
			onGtinChange,
			onLotChange,
			onSerialChange,
			onExpiryChange,
			onSubmit,
			onClose: props.onClose,
			showError: !firstAttempt
		} }/>
	);
};

const useStyles = makeStyles(theme =>
		createStyles({
			root: {
				display: 'flex',
				flexDirection: 'column',
				minHeight: '100%'
			},
			form: {
				'& .MuiFormControl-root': {
					width: '100%',
				},
			},
			container:{
				backgroundColor: theme.background.main,
				flex: 'auto'
			},
			content: {
				padding: '30px'
			},
			backButton: {
				marginRight: theme.spacing(2),
			}
		}),
	);

const CodeEntryView: React.FunctionComponent<ViewProps> = (props: ViewProps) => {
	const classes = useStyles();

	const handleGtinChange = useCallback(
		(e: ChangeEvent<{ value: unknown }>) => props.onGtinChange(e.target.value as string),
		[props.onGtinChange]
	);

	const handleLotChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => props.onLotChange(e.target.value), 
		[props.onLotChange]
	); 

	const handleSerialChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => props.onSerialChange(e.target.value), 
		[props.onSerialChange]
	); 

	const handleExpiryChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => props.onExpiryChange(e.target.value),
		[props.onExpiryChange]
	);

	const validLot = validateLot(props.lot);
	const validSerial = validateSerial(props.serial);
	const validExpiry = validateExpiry(props.expiry);

	return (
		<Box className={classes.root}>
			<HeaderAppBar>
				<IconButton edge="start" className={classes.backButton} onClick={props.onClose} color="inherit" aria-label="menu">
					<ArrowBackIosIcon />
				</IconButton>
				<Typography variant="h6">
					輸入產品資料
				</Typography>
			</HeaderAppBar>

			<Grid container justify="center" className={classes.container}>
				<Grid item xs={12} sm={6} md={3} className={classes.content}>
					<Box>
						<Typography variant="body1">
							請輸入產品盒上「GTIN」，「S/N」，「LOT」和「Expiry」三組數字。
						</Typography>
					</Box>
					<br/>
					<form className={classes.form} noValidate autoComplete="off">
						<FormControl variant="filled">
							<InputLabel id="gtin-select-label">GTIN</InputLabel>
							<Select 
							labelId="gtin-select-label"
							id="gtin"
							value={props.gtin}
							onChange={handleGtinChange}
							>
								<MenuItem value={FIXED_CODE_ENTRY_GTINS[0]}>{FIXED_CODE_ENTRY_GTINS[0]}</MenuItem>
								<MenuItem value={FIXED_CODE_ENTRY_GTINS[1]}>{FIXED_CODE_ENTRY_GTINS[1]}</MenuItem>
								<MenuItem value={FIXED_CODE_ENTRY_GTINS[2]}>{FIXED_CODE_ENTRY_GTINS[2]}</MenuItem>
							</Select>
						</FormControl>
					</form>
					<br/>
					<form className={classes.form} noValidate autoComplete="off">
						<TextField
						id="serial"
						label="S/N"
						variant="filled"
						error={props.showError ? !validSerial : false}
						value={props.serial}
						helperText={(props.showError && !validSerial) ? 'S/N號碼不正確' : null}
						onChange={handleSerialChange}
						/>
					</form>
					<br/>
					<form className={classes.form} noValidate autoComplete="off">
						<TextField
						id="lot"
						label="LOT"
						variant="filled"
						error={props.showError ? !validLot : false}
						value={props.lot}
						helperText={(props.showError && !validLot) ? 'LOT號碼不正確' : null}
						onChange={handleLotChange}
						/>
					</form>
					<br/>
					<form className={classes.form} noValidate autoComplete="off">
						<TextField
						id="expiry"
						label="Expiry"
						variant="filled"
						error={props.showError ? !validExpiry : false}
						value={props.expiry}
						helperText={(props.showError && !validExpiry) ? 'Expiry不正確' : null}
						onChange={handleExpiryChange}
						/>
					</form>
					<br/>
					<Box textAlign="center">
						<Button 
						variant="contained" 
						color="secondary" 
						disabled={!(validLot && validSerial && validExpiry)}
						onClick={props.onSubmit}>
							提交
						</Button>
					</Box>
				</Grid>
			</Grid>

			<FooterAppBar showLeftLogo/>
		</Box>
	)
};

function convertExpiry(expiry: string): string {
	const matched = expiry.match(/^ *([0-9]{4}) ?([0-9]{2}) *$/);
	if (matched) {
		const year = parseInt(matched[1], 10);
		const month = parseInt(matched[2], 10);

		// month here is 0 based (i.e. 0 = Jan)
		// 0th date means the last date of the previous month
		const date = new Date(year, month, 0);

		// format the date as yymmdd
		let dateStr = date.toLocaleDateString('en-US', { year: '2-digit' });
		dateStr += date.toLocaleDateString('en-US', { month: '2-digit' });
		dateStr += date.toLocaleDateString('en-US', { day: '2-digit' });

		return dateStr;
	}

	return "";
}

function validateGtin(gtin: string): boolean {
	return FIXED_CODE_ENTRY_GTINS.includes(gtin);
}

function validateLot(lot: string): boolean {
	const matched = lot.trim().match(/^[a-zA-Z0-9]{1,20}$/);
	return !!matched;
}

function validateSerial(serial: string): boolean {
	const matched = serial.trim().match(/^[a-zA-Z0-9]{1,20}$/);
	return !!matched;
}

function validateExpiry(expiry: string): boolean {
	return convertExpiry(expiry) !== "";
}

export default CodeEntryContainer;