import * as React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core';

interface FooterAppBarProps {
	showLeftLogo?: boolean
}

const useStyles = makeStyles(theme => createStyles({
	footerBar: {
		top: 'auto',
		bottom: '0',
	},
	footerBarContainer: {
		height: '30px',
	},
	footerBarLogos: {
		display: 'inline-flex',
		height: '25px',
		width: '100%',
		alignItems: 'center'
	}
}));

const FooterAppBar: React.FunctionComponent<FooterAppBarProps> = (props: FooterAppBarProps) => {
	const classes = useStyles();

	return (
		<>
			<Toolbar className={classes.footerBarContainer}/>
			<AppBar position="fixed" color="primary" className={classes.footerBar}>
				<Toolbar className={classes.footerBarContainer}>
					<Box className={classes.footerBarLogos}>
						{ props.showLeftLogo ? <img src="/logo_white.png" height="70%"/> : null }
						<img src="/almalogo.svg" height="60%" style={{marginLeft: 'auto', marginRight: '15px'}}/>
						<img src="/ibsa_white.png" height="100%"/>
					</Box>
				</Toolbar>
			</AppBar>
		</>
	);
}

FooterAppBar.defaultProps = {
	showLeftLogo: false
};

export default FooterAppBar;