export class ProductInstanceNotFoundError extends Error {
	constructor() {
		super("product instance not found");
	}
}

export class OperationError extends Error {
	readonly code: string
	
	constructor(code: string, msg: string) {
		super(msg);
		this.code = code;
	}
}