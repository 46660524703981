import * as React from 'react';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FilterCenterFocusSharpIcon from '@material-ui/icons/FilterCenterFocusSharp';
import { Box } from '@material-ui/core';

import HeaderAppBar from 'component/header-app-bar';
import FooterAppBar from 'component/footer-app-bar';

const useStyles = makeStyles((theme) =>
  createStyles({
	root: {
		backgroundColor: 'rgb(233,233,233)',
		minHeight: '100%'
	},
	appbar: {
		backgroundColor: '#ffffff'
	},
	appbarLogo: {
		width: 'auto',
		height: '50%', 
		margin: 'auto'
	},
	firstSection: {
		backgroundColor: theme.background.main,
		padding: '20px 10px'
	},
	firstSectionText: {
		display: 'flex',
		height: '100%',
		textAlign: 'center',
		alignItems: 'center',
		justifyContent: 'center',
	},
	secondSection: {
		backgroundColor: 'rgb(233,233,233)',
		padding: '30px 20px',
		color: '#4a4a4a',
		textAlign: 'center'
	},
	sampleBox: {
		padding: '20px 0'
	},
	scanButton: {
		fontSize: '1.5em',
		'& svg': {
			fontSize: 'inherit !important'
		}
	}
  }),
);

const HomeView: React.FunctionComponent = () => {
	const history = useHistory();
	const classes = useStyles();

	const onClick = useCallback(() => {
		history.push('/scan');
	}, [history]);

	return (
		<Box className={classes.root}>
			<HeaderAppBar className={classes.appbar}>
				<Box className={classes.appbarLogo}>
					<img src="logo_black.png" height="100%"/>
				</Box>
			</HeaderAppBar>

			<Grid container>
				<Grid item xs={12} sm={6} md={3}>
					<Box>
						<img src="banner_2024.png" width="100%"/>
					</Box>
					<Box>
						<img src="banner_lower_2024.png" width="100%"/>
					</Box>
					<Box className={classes.secondSection}>
						<Typography variant="h6" align="center">
							立即掃描盒上獨一無二嘅QR Code
						</Typography>
						<Box className={classes.sampleBox}>
							<img src="code_sample_2024.png" width="60%"/>
						</Box>
						<Box>
							<Button variant="contained" color="secondary" size="large" className={classes.scanButton} onClick={onClick} startIcon={<FilterCenterFocusSharpIcon/>}>
								立即掃描
							</Button>
						</Box>
					</Box>
				</Grid>
			</Grid>

			<FooterAppBar/>
		</Box>
	);
}
export default HomeView;