import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useState, useCallback, useRef, ChangeEvent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import GS1Code from '../model/gs1code';
import DataMatrixScanner from '../component/data-matrix-scanner';
import HeaderAppBar from 'component/header-app-bar'

import { BrowserDatamatrixCodeReader } from '@zxing/browser';

interface ContainerProps {
	onDetected?: (code: GS1Code) => void,
	gotoCodeEntry: () => void
}

interface ViewProps {
	onCodeDetected?: (code: string) => void,
	onScannerError?: (err: any) => void,
	gotoCodeEntry: () => void,
	onFileUploadSelected: () => void,
}

const ScannerContainer: React.FunctionComponent<ContainerProps> = (props: ContainerProps) => {
	const [shouldProcess, setShouldProcess] = useState(true);
	const [err, setErr] = useState<{title: string, errObj: any} | null>(null);

	const fileInputRef = useRef<HTMLInputElement>(null);

	const processScannedCode = (code: string) => {
		const gs1 = GS1Code.parse(code);
		// return the parsed GS1 code to parent
		if (gs1) {
			props.onDetected?.(gs1);
		} else {
			setErr({
				title: 'QR Code 無效',
				errObj: '您所掃描的QR Code並不屬於Profhilo產品'
			});
		}
	};

	const handleScannerError = useCallback((err: any) => {
		let errObj = err;
		if (err instanceof Error && err.name == 'NotAllowedError') {
			errObj = '沒有權限使用相機。請到流灠器設定允許本網頁使用相機'
		}

		setErr({
			title: '無法掃描',
			errObj: errObj
		});
	}, [setErr]);
	const handleErrClose = useCallback(() => {
		setErr(null);
		setShouldProcess(true);
	}, [setErr, setShouldProcess]);

	// only process the first detect code
	const onCodeDetected = useCallback((code: string) => {
		if (shouldProcess) {
			setShouldProcess(false);
			processScannedCode(code);
			setShouldProcess(true);
		}
	}, [shouldProcess, setShouldProcess, setErr, processScannedCode]);

	const onFileUploadSelected = useCallback(() => {
		// to select a pic of the code instead
		fileInputRef.current?.click();
		// stop processing the stream
		setShouldProcess(false);
	}, [fileInputRef, setShouldProcess]);

	const handleFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		if (files) {
			// a image file is selected
			const reader = new BrowserDatamatrixCodeReader();

			// prepare a canvas and draw the image file onto the canvas
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');

			const img = new Image();
			img.onload = () => {
				// set the canvas size to be 1/10 of the original image size
				const w = Math.floor(img.width / 10);
				const h = Math.floor(img.height / 10);

				canvas.width = w;
				canvas.height = h;

				// draw the image
				ctx?.drawImage(img, 0, 0, w, h);

				try {
					// decode the canvas image
					const result = reader.decodeFromCanvas(canvas);
					// process the decoded text
					processScannedCode(result.getText());
				} catch(error) {
					// unable to decode
					setErr({
						title: '偵測失敗',
						errObj: '未能成功偵測QR Code。請盡量放大相中QR Code面積。'
					});
				} finally {
					// resume processing the stream
					setShouldProcess(true);
				}
			};

			// add the file to the image object as an object URL
			img.src = URL.createObjectURL(files[0]);
		} else {
			// no file selected, resume processing the stream
			setShouldProcess(true);
		}
		e.target.value = "";
	}, [onCodeDetected, setShouldProcess, setErr, processScannedCode]);

	return (
		<>
			<ScannerView 
				onCodeDetected={onCodeDetected} 
				onScannerError={handleScannerError} 
				onFileUploadSelected={onFileUploadSelected}
				gotoCodeEntry={props.gotoCodeEntry}/>
			<ErrorDialog 
			title={err?.title} 
			err={err?.errObj}
			onClose={handleErrClose}/>
			<input 
				type="file" name="myImage" 
				ref={ fileInputRef }
				accept="image/＊;capture=camera" 
				onChange={handleFileChange} 
				style={ { display: 'none' }} />
		</>
	);
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
	  display: 'flex',
	  height: '100%',
	  width: '100%',
	  flexDirection: 'column'
	},
	container: {
		height: '100%'
	},
	instruction: {
		position: 'absolute',
		top: '10%',
		width: '100%',
		color: '#ffffff',
		textAlign: 'center',
		fontSize: '1.5em'
	},
	alternativeInput: {
		position: 'absolute',
		width: '100%',
		bottom: '10%',
		color: '#ffffff',
		textAlign: 'center',
		fontSize: '1.1em'
	},
	gridItem: {
		position: 'relative',
	},
    backButton: {
      marginRight: theme.spacing(2),
    },
  }),
);

const ScannerView: React.FunctionComponent<ViewProps> = (props: ViewProps) => {
	const classes = useStyles();
	const history = useHistory();

	const onClickBack = useCallback(() => history.goBack(), [history]);

	return (
		<Box className={classes.root}>
			<HeaderAppBar>
				<IconButton edge="start" className={classes.backButton} onClick={onClickBack} color="inherit" aria-label="menu">
				<ArrowBackIosIcon />
				</IconButton>
				<Typography variant="h6">
					QR Code 掃描
				</Typography>
			</HeaderAppBar>

			<Grid container className={classes.container} justify="center">
				<Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
					<DataMatrixScanner width="100%" height="100%" onDetected={props.onCodeDetected} onError={props.onScannerError}/>
					<Box className={classes.instruction}>
						請對準產品盒上 QR Code
					</Box>
					<Box className={classes.alternativeInput}>
						如未能掃描，可上載照片或自行輸入產品資料<br/><br/>
						<Button onClick={props.onFileUploadSelected} variant="contained" size="medium" color="secondary" style={{marginRight: '15px'}}>
							上載照片
						</Button>
						<Button onClick={props.gotoCodeEntry} variant="contained" size="medium" color="secondary">
							輸入資料
						</Button>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

export default ScannerContainer;

interface ErrorDialogProps {
	title?: string,
	err?: any,
	onClose: () => void,
}

const ErrorDialog: React.FunctionComponent<ErrorDialogProps> = (props) => {
	let message = '';
	if (props.err) {
		message = props.err instanceof Error ?
			`${props.err.name}: ${props.err.message}`:
			props.err;
	}

	return (
		<Dialog
		open={!!props.err}
		onClose={props.onClose}
		aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
			<DialogTitle id="alert-dialog-title">
				{ props.title }
			</DialogTitle>
			<DialogContent>
          		<DialogContentText id="alert-dialog-description">
					{ message }
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose} color="primary" autoFocus>
					關閉
				</Button>
			</DialogActions>
		</Dialog>
	)
}