import * as React from 'react';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { Alert, AlertTitle } from '@material-ui/lab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import HomeIcon from '@material-ui/icons/Home';
import GS1Code from 'model/gs1code';
import { ProductInstanceSumamry } from 'model/product';
import HeaderAppBar from 'component/header-app-bar';
import FooterAppBar from 'component/footer-app-bar';

interface ContainerProps {
	pending: boolean,
	code: GS1Code,
	result?: ProductInstanceSumamry
}

interface ResultViewProps {
	pending: boolean,
	code: GS1Code,
	result?: ProductInstanceSumamry
}

interface QueryViewProps {
	code: GS1Code
} 

interface NotFoundViewProps {
	code: GS1Code
}

interface ProductCodeTableProps {
	code: GS1Code
}

interface SummaryViewProps {
	result: ProductInstanceSumamry
}

const ResultContainer: React.FunctionComponent<ContainerProps> = (props: ContainerProps) => {
	return (
		<ResultView {...props}/>
	);
}
ResultContainer.defaultProps = {
	pending: true
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '100%'
	},container:{
		backgroundColor: theme.background.main,
		flex: 'auto'
	},
    backButton: {
      marginRight: theme.spacing(2),
	},
	warning: {
		fontSize: '1.1em'
	},
    title: {
      flexGrow: 1,
    },
  }),
);


const ResultView: React.FunctionComponent<ResultViewProps> = (props: ResultViewProps) => {
	const classes = useStyles();
	const history = useHistory();

	var content: React.ReactElement;
	if (props.pending) {
		content = <QueryView code={props.code}/>;
	} else if (props.result) {
		content = <SummaryView result={props.result}/>;
	} else {
		content = <NotFoundView code={props.code}/>;
	}

	const onClickDone = useCallback(() => history.push('/'), [history]);

	return (
		<Box className={classes.root}>
			<HeaderAppBar>
				<IconButton edge="start" className={classes.backButton} color="inherit" aria-label="menu">
				</IconButton>
				<Typography variant="h6" className={classes.title}>
					產品資料
				</Typography>
				<Button onClick={onClickDone} color="secondary">完成</Button>
			</HeaderAppBar>

			<Grid container className={classes.container} justify="center">
				<Grid item xs={12} sm={6} md={3}>
					{ content }
				</Grid>

				<Box>
					<Button variant="contained" color="secondary" size="large" onClick={onClickDone} startIcon={<HomeIcon/>}>
						回到主頁
					</Button>
				</Box>
			</Grid>

			<FooterAppBar showLeftLogo/>
		</Box>
	);
}

const QueryView: React.FunctionComponent<QueryViewProps> = (props: QueryViewProps) => {
	return (
		<Box>
			<Card variant="outlined">
				<CardContent>
					<Box my={3}>
						<Box mb={1} fontSize="1.2em" fontWeight="bold">
							尋找貨品中 。。。
						</Box>
						<ProductCodeTable code={props.code}></ProductCodeTable>
					</Box>
				</CardContent>
			</Card>

			<Box textAlign="center" mt="20">
				<CircularProgress/>
			</Box>
		</Box>
	)
}

const SummaryView: React.FunctionComponent<SummaryViewProps> = (props: SummaryViewProps) => {
	const classes = useStyles();

	const instInfo = props.result.instance;
	const scanCount = props.result.count;
	const lastScan = props.result.last_scan_at;

	let history: React.ReactNode;
	if (scanCount > 1) {
		let lastScanString = '---';

		if (lastScan) {
			const dateString = lastScan.toLocaleDateString('zh-HK', {year: 'numeric', month: 'long', day: 'numeric'});
			const timeString = lastScan.toLocaleTimeString('zh-HK');

			lastScanString = `${dateString} ${timeString}`;
		}

		history = (
			<>
				<div>此產品代碼已被掃描 <strong>{scanCount - 1}</strong> 次</div>
				<div>上一次掃描日期: <strong>{ lastScanString }</strong></div>
			</>
		);
	} else {
		history = <div>這是此產品代碼第一次被掃描</div>;
	}

	return (
		<Box>
			<Alert severity="success">
				<AlertTitle>原廠正貨</AlertTitle>
				此產品由IBSA從義大利直送，為原廠正貨，請放心使用
			</Alert>
			<Card variant="outlined">
				<CardContent>
					<Box mb={3}>
						<Box mb={1} fontSize="1.2em" fontWeight="bold">
							掃描記錄
						</Box>
						{ history }
					</Box>
					<Box my={3}>
						<Box mb={1} fontSize="1.2em" fontWeight="bold">
							貨品資料
						</Box>
						<ProductCodeTable code={(instInfo as GS1Code)}></ProductCodeTable>
					</Box>
					<Alert icon={false} severity="warning" variant="outlined">
						<strong>溫馨提示：</strong> 接受療程前，請確保醫生或職員在您面前把貨品開封。
					</Alert>
				</CardContent>
			</Card>
		</Box>
	)
}

const NotFoundView: React.FunctionComponent<NotFoundViewProps> = (props: NotFoundViewProps) => {
	return (
		<Box>
			<Alert severity="error">
				<AlertTitle>無法找到貨品</AlertTitle>
				我們<strong>無法</strong>根據您所提供的產品代碼找到貨品記錄<br/>
				此貨品有可能是水貨或假貨<br/><br/>
				<strong>為確保安全，請勿使用此產品</strong>
			</Alert>
			<Card variant="outlined">
				<CardContent>
					<Box my={3}>
						<Box mb={1} fontSize="1.2em" fontWeight="bold">
							無法找到此產品
						</Box>
						<ProductCodeTable code={props.code}></ProductCodeTable>
					</Box>
				</CardContent>
			</Card>
		</Box>
	)
}

const ProductCodeTable: React.FunctionComponent<ProductCodeTableProps> = (props: ProductCodeTableProps) => {
	const { code } = props;

	return (
		<TableContainer>
			<Table>
				<TableBody>
					<TableRow>
						<TableCell component="th" scope="row">GTIN</TableCell>
						<TableCell align="right">{ code.gtin }</TableCell>
					</TableRow>
					<TableRow>
						<TableCell component="th" scope="row">Serial</TableCell>
						<TableCell align="right">{ code.serial }</TableCell>
					</TableRow>
					<TableRow>
						<TableCell component="th" scope="row">Lot</TableCell>
						<TableCell align="right">{ code.lot }</TableCell>
					</TableRow>
					<TableRow>
						<TableCell component="th" scope="row">Expiry</TableCell>
						<TableCell align="right">{ code.expiry }</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default ResultContainer;