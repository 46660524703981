import * as React from 'react';
import { useRef, useEffect, RefObject } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import CroppedCodeReader from 'lib/cropped-code-reader';

const useStyles = makeStyles({
	view: {
		position: 'relative',
		width: (props: any) => props.width,
		height: (props: any) => props.height,
	},
	vidDisplay: {
		backgroundColor: 'black',
		objectFit: 'cover'
	}
});

interface StreamDisplayProps {
	videoRef: RefObject<HTMLVideoElement>
}

interface OverlayProps {
	crop: number
}

type ViewProps = {
	width?: number | string | undefined,
	height?: number | string | undefined,
} & StreamDisplayProps & OverlayProps;

interface ContainerProps {
	crop?: number,
	width?: number | string | undefined,
	height?: number | string | undefined,
	onDetected?: (code: string) => void,
	onError?: (err: any) => void,
}

const ScannerContainer: React.FunctionComponent<ContainerProps> = ({ 
	crop = 0.4, ...props 
}) => {
	const videoRef = useRef<HTMLVideoElement>(null);

	useEffect(() => {
		const codeReader = new CroppedCodeReader(crop);

		(async () => {
			try {
				assertUserMediaCapable();
	
				const stream = await autoSelectStream();
				const vid = videoRef.current;
				if (vid) {
					codeReader.decodeFromStream(stream, vid, (result, err) => {
						if (result) {
							props.onDetected?.(result.getText());
						}
					});
				}
			} catch (e) {
				props.onError?.(e);
			}
		})()
	}, [props.onDetected, props.onError]);

	return <ScannerView crop={crop} videoRef={videoRef}/>;
};
export default ScannerContainer;

const ScannerView: React.FunctionComponent<ViewProps> = (props) => {
	const classes = useStyles(props);

	return (
		<div className={classes.view}>
			<CameraOverlay crop={props.crop}/>
			<StreamDisplay videoRef={props.videoRef}/>
		</div>
	);
};

const StreamDisplay: React.FunctionComponent<StreamDisplayProps> = (props) => {
	const classes = useStyles();

	return (
		<video
		className={classes.vidDisplay}
		ref={props.videoRef}
		width="100%"
		height="100%"
		autoPlay={ false }
		/>
	);
};

const overlayUseStyles = makeStyles({
	overlay: {
		position: 'absolute',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		width: '100%',
		overflow: 'hidden'
	},
	cutout: {
		backgroundColor: 'transparent',
		width: (props: any) => `${Math.floor(props.crop * 100)}%`,
		paddingTop: (props: any) => `${Math.floor(props.crop * 100)}%`,
		boxShadow: '0 0 0 99999px rgba(0, 0, 0, .6)'
	}
});
const CameraOverlay: React.FunctionComponent<OverlayProps> = (props) => {
	const classes = overlayUseStyles(props);

	return (
		<div className={classes.overlay}>
			<div className={classes.cutout}></div>
		</div>
	);
}

function assertUserMediaCapable() {
	if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
		throw new Error("Your browser does not support the use of camera");
	}
}

async function autoSelectStream(): Promise<MediaStream> {
	let stream: MediaStream;
	let lastError: any;

	try {
		// attempt to let the browser select first
		stream = await getVideoStream();
		return stream;
	} catch (e) {
		// re-throw all error except NotReadableError
		if (e instanceof Error && e.name != 'NotReadableError') throw e;
		lastError = e;
	}

	// Chrome appears to have issue where it would select the wrong camera
	// so we will manually iterate through the list of devices 
	// @see https://github.com/jeromeetienne/AR.js/issues/692
	const devices = await listVideoDevices();
	if (devices) {
		for (const d of devices) {
			try {
				stream = await getVideoStream(d.deviceId);
				return stream;
			} catch (e) {
				// OverconstrainedError is not considered error because
				// we are forcing "facingMode" constraints on all devices
				// and it is expected that some devices may not face the right
				// direction
				if (e instanceof Error && e.name != 'OverconstrainedError') lastError = e;
			}
		}
	}

	throw lastError;
}

async function listVideoDevices(): Promise<MediaDeviceInfo[] | undefined> {
	if (navigator.mediaDevices.enumerateDevices) {
		const devices = await navigator.mediaDevices.enumerateDevices();
		const list: MediaDeviceInfo[] = [];

		for (const d of devices) {
			if (d.kind == 'videoinput') {
				list.push(d);
			}
		}

		list.sort((a, b): number => a.label.localeCompare(b.label));

		return list;
	} 

	return undefined;
}

async function getVideoStream(deviceId?: string): Promise<MediaStream> {
	let trackConstraints: MediaTrackConstraints;
	if (deviceId) {
		trackConstraints = { 
			//@ts-ignore
			focusMode: { exact: 'continuous' }, 
			facingMode: { exact: 'environment' },
			deviceId: { exact: deviceId },
		};
	} else {
		trackConstraints = { 
			//@ts-ignore
			focusMode: 'continuous',
			facingMode: 'environment',
			width: { min: 240 },
			height: { min: 320 },
		};
	}

	return await navigator.mediaDevices.getUserMedia({
		audio: false,
		video: trackConstraints
	});
}