import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles';

declare module '@material-ui/core/styles' {
	interface Theme {
		background: {
			light: React.CSSProperties['color'];
			main: React.CSSProperties['color'];
		}
	}

	interface ThemeOptions {
		background: {
			light: React.CSSProperties['color'];
			main: React.CSSProperties['color'];
		}
	}
}

const theme = createMuiTheme({
  	palette: {
    	primary: {
      		// light: will be calculated from palette.primary.main,
      		main: '#2f2f2f',
      		// dark: will be calculated from palette.primary.main,
      		// contrastText: will be calculated to contrast with palette.primary.main
		},
		secondary: {
			main: '#9fc452',
			contrastText: '#ffffff'
		}
	},
	background: {
		light: '#ffffff',
		main: '#f7f7f7'
	}
});

export default theme;