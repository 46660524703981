import * as React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => createStyles({
	appbar: {
		height: '65px'
	}
}));

interface HideOnScrollProps {
	children: React.ReactElement
}

interface HeaderAppBarProps {
	className?: string,
	children: React.ReactNode
}

function HideOnScroll(props: HideOnScrollProps) {
	const { children } = props;
	const trigger = useScrollTrigger();

	return (
		<Slide appear={false} direction="down" in={!trigger}>
			{children}
		</Slide>
	);
}

const HeaderAppBar: React.FunctionComponent<HeaderAppBarProps> = (props:HeaderAppBarProps) => {
	const classes = useStyles();

	return (
		<>
			<HideOnScroll>
				<AppBar variant="elevation" color="primary" className={props.className}>
					<Toolbar className={classes.appbar} >
						{ props.children }
					</Toolbar>
				</AppBar>
			</HideOnScroll>
			<Toolbar className={classes.appbar}/>
		</>
	);
}

export default HeaderAppBar;